import React from "react";
import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";

const Page = () => {
  return (
    <LessonLayout
      current_step={15}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 15</H1>

        <p>After you have finished adding criteria to your rubric, look again at the CBD websites and use your new rubric section about risk and benefits to score those sites.</p>
        <div className="flex place-content-between">
          <Button
            action="secondary"
            color="purple"
            href="/article/cbd/cbd-now"
            icon="external"
            title="CBD website 1"
          >
            CBD website 1
          </Button>
          <Button
            action="secondary"
            color="purple"
            href="/article/cbd/cbd-truth"
            icon="external"
            title="CBD website 2"
          >
            CBD website 2
          </Button>
          <Button
            action="secondary"
            color="purple"
            href="/article/cbd/cansativa-organics"
            icon="external"
            title="CBD website 3"
          >
            CBD website 3
          </Button>
        </div>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

